@keyframes gradientBg {
    0%{background-position:93% 0%}
    50%{background-position:0% 100%}
    100%{background-position:93% 0%}
}

@keyframes moveX {
    0% {transform: translateX(0px) rotate(-20deg)}
    100% {transform: translateX(20px) rotate(-20deg)}
}

@keyframes moveY {
    0% {transform: translateY(0px) rotate(15deg)}
    100% {transform: translateY(20px) rotate(15deg)}
}

@keyframes moveXY {
    0% {transform: translateY(0px) translateX(0px) rotate(0deg)}
    100% {transform: translateY(20px) translateX(20px) rotate(4deg)}
}



.home {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100%;
    overflow-x: hidden;

    * { transition : all .5s }
}

.home__section {
    scroll-snap-align: start;
    height : 100%;

    .section__content {
        position: relative;
        height : 100%;
    }
}

.home__1 {
    
    animation: gradientBg 19s ease infinite;
    background: linear-gradient(59deg, rgb(255,145,83), rgb(255,209,67));
    background-size: 190px 150%;


    .section__content {

        .home__1__titles {
            z-index:2;
            position : absolute;
            display : flex;
            justify-content: space-between;
            flex-direction: column;
            top: 35%;
            left : 13%;
            height : 310px;

            h1 {
                font-size : 70px;
                font-weight : bold;
                white-space: nowrap; /* Keeps the content on a single line */
                letter-spacing: .10em;
                color : #1B1B1B;
            }

            .wrapper {
                display: flex;
                align-items: center;

                .static-txt {
                    color: #1B1B1B;
                    font-size: 70px;
                    font-weight: 400;
                    letter-spacing: 0.05em;
                    white-space: nowrap;
                }

                .dynamic-txts {
                    margin-left: 30px;
                    height: 90px;
                    line-height: 90px;
                    overflow: hidden;

                    li {
                        list-style: none;
                        font-size: 70px;
                        font-weight: bold;
                        position: relative;
                        top: 0;
                        width : fit-content;
                        animation: slide 12s steps(4) infinite;

                        &:nth-of-type(4) h1 {
                            letter-spacing: 12px;
                            animation: typing 1.5s steps(18, end) infinite alternate, blink-caret 0.7s step-end infinite;
                        }

                        h1 {
                            color: #cc3131;
                            overflow: hidden;
                            border-right: 0.15em solid #cc3131;
                            white-space: nowrap;
                            letter-spacing: 8px;
                            animation: typing 1.5s steps(30, end) infinite alternate, blink-caret 0.7s step-end infinite;
                            font-size: 70px;
                        }
                    }
                }
            }

                  @keyframes slide {
                    100% {
                      top: -360px;
                    }
                  }

                  
                  /* The typing effect */
                  @keyframes typing {
                    from {
                      width: 0;
                    }
                    to {
                      width: 100%;
                    }
                  }
                  
                  /* The typewriter cursor effect */
                  @keyframes blink-caret {
                    from,
                    to {
                        border-right: 0.15em solid transparent;
                      }
                      50% {
                        border-right: 0.15em solid #cc3131;
                      }
                  }
            
            a {
                backdrop-filter: $blur;
                display:flex;
                justify-content: center;
                align-items: center;
                color:#383838;
                background:rgba(255, 255, 255, 0.349);
                border:none;
                width:200px;
                height:50px;
                border-radius: 60px;
                font-weight: bold;
                margin-top:30px;
                transition: all .5s;
                font-size: 17px;
                
                &:hover {
                    background:$color-red;
                    color:white;
                    cursor:pointer;
                }
            }

            @media (max-width: 810px) {
                height:280px;

                h1, .wrapper .static-txt, .wrapper .dynamic-txts li h1 {
                    font-size:65px !important; 
                }
            }

            @media (max-width: 734px) {
                height: 240px;

                h1, .wrapper .static-txt, .wrapper .dynamic-txts li h1 {
                    font-size:52px !important; 
                }
            }

            @media (max-width: 595px) {
                height: 230px;

                h1, .wrapper .static-txt, .wrapper .dynamic-txts li h1 {
                    font-size:48px !important; 
                }
            }
        }

        .headphones__content {
            animation : moveX infinite alternate 3s linear;
            top:60%;
            left:70%;
        }

        .cup__content {
            animation : moveXY 3s infinite alternate 1.5s linear;
            top:63%;
            right: 45%;
        }

        .phone__content {
            animation : moveY infinite alternate 3s linear;
            left:60%;
            top:20%;
            transform: rotate(15deg);
        }

        @media (max-width: 1350px) {
            .headphones__content {
                top:60%;
                left:72%;
            }
    
            .cup__content {
                top:66%;
                right: 45%;
            }
    
            .phone__content {
                top:15%;
                left:65%;
            }
        }

        @media (max-width: 1100px) {
            .cup__content {
                top:70%;
                right: 46%;
            }

            .phone__content {
                top:12%;
                left: 73%;
            }
        }

        @media (max-width: 800px) {
            .headphones__content {
                top:61%;
                left:60%;
            }

            .cup__content {
                top:73%;
                right: 60%;
            }

            .phone__content {
                top:4%;
                left: 70%;
            }
        }

        @media (max-width: 640px) {
            .headphones__content {
                top:61%;
                left:52%;
            }

            .cup__content {
                top:73%;
                right: 68%;
            }

            .phone__content {
                top:2%;
                left: 68%;
            }
        }
    }
}

    

.home__2 {
    animation: gradientBg 40s linear infinite;
    background: linear-gradient(59deg, #FF7426, #FF9E63);
    background-size: 180px 180px;
}

.home__3 {
    animation: gradientBg 25s ease infinite;
    background: linear-gradient(59deg, #FFB884, #FF2F2F);
    background-size: 85px 150%;

    .section__content {
        display:flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .drag {
            justify-self: flex-end;
        }
    }
}

.home__4 {
    animation: gradientBg 40s linear infinite;
    background: linear-gradient(59deg, #e95858, #ec2b2b);
    background-size: 180px 180px;
}