$color : #5c4747;

.contact {
    width:100%;
    height:100%;
    display:flex;
    align-items: center;
    justify-content: center;

    .contact__left {
        display:flex;
        justify-content: center;
        align-items: center;
        width:50%;
        height:100%;

        * { transition: all .5s }
    } 

    .contact__right {
        width:50%;
        height:100%;
    }

    @media (max-width: 1100px) {
        flex-direction: column;

        .contact__left {
            order:2;
            width:100%;
            margin-top:5%;
        }

        .contact__right { display:none }
    }
}

// FORM ----------------------------

.contact-form {
    min-width:353px;
    max-width: 500px;
    color:rgba(196, 196, 196, 0.671);
    font-weight:bold;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:60%;
    height:60%;
    background: rgba(255, 255, 255, 0.219);
    padding: 3.55%;
    border-radius: 60px;
    backdrop-filter: $blur;

    .form__box {
        width:100%;
        margin-bottom:4%;
        display:flex;
        justify-content: space-between;
        align-items: center;

        .box__icon {
            width:16%;
            height:55px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right:6%;
            font-size: 180%;

            svg {
                font-size:89%;
                color:$color;
            }

            @media (max-width: 770px) { height:52px }
        
            @media (max-width: 700px) {
                font-size:160%;
                height:49px;
            }
        
            @media (max-width: 655px) { height:46px }
        
            @media (max-width: 630px) { height:43px }
        }

        @media (max-width: 512px) {
            input { height:45px }
            .box__icon { display:none }
        }
    }

    .box__icon, input, textarea {
        background: rgba(196, 196, 196, 0.41);
        font-size:15px;
        color:$color;
        font-weight:bold;
    }

    input::placeholder, textarea::placeholder {
        color:#776565;
        font-weight: bold;
    }

    input {
        width:100%;
        padding: 0 5%;
        height:100%;
        border-radius: 100px;
        border:none;
        outline: none;
    }

    textarea {
        padding: 5%;
        width:100%;
        height:150px;
        border:none;
        outline: none;
        border-radius: 50px;
        resize: none;
    }

    .form__bottom {

        display:flex;
        width:100%;
        justify-content: space-between;
        align-items: center;
        height:8%;

        .bottom_captcha {

            margin-left:18.3%;
            display:flex;
            align-items: center;
            justify-content: flex-start;
            height:100%;

            label {
                margin-right:18%;
                white-space: nowrap;
            }
            
            input[type="number"] {
                width:35px;
                padding: 0 16%;
            }

            // hide arrow from input number --------------

            // Chrome, Safari, Edge, Opera
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }

            // Firefox
            input[type=number] { -moz-appearance: textfield }

            // -------------------------------------------
        }

        input[type="submit"] {
            width:30%;
            align-self: flex-end;
            color: $color-red;
            font-size: 23px;
            font-weight: bold;
            transition:all .5s;
    
            &:hover {
                background:$color-red;
                color:rgba(255, 255, 255, 0.664);
                cursor:pointer;
            }
        }
    }
}