@import url('https://fonts.googleapis.com/css2?family=Passions+Conflict&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

$color-red: #EB4039;
$blur: blur(5px);


$color-primary : rgb(255, 97, 79);
$color-secondary : rgb(82, 62, 62);
$color-black: rgb(29,29,29);
// grey of home
$color-grey: #b1b1b1;
$color-grey-dark: rgb(128, 128, 128);
$font-primary : 'Roboto', sans-serif;
$font-titles : 'Passions Conflict', cursive;


*, .root {
  margin: 0;
  padding: 0;
  font-family: $font-primary;

  &::selection {
    color : white;
    background : rgb(247, 61, 61);
  }

  &::-webkit-scrollbar {
    width: 15px;
    background-color: #2b2b2b;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(44, 44, 44,0.3);
    background-color: #c9a474;
  }
  
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(44, 44, 44,0.3);
    border-radius: 10px;
    background-color: rgb(44, 44, 44);
  }
}

body {
  background-color : rgb(29,29,29);
  overflow-x:none;
}

a {
  text-decoration: none;
}


.main {
  height : 100vh;
  width : 100vw;
}


// TOAST -------------------------------------------

/* Class for the displayed toast */
.Toastify__toast { //
  background: rgb(29,29,29);
}

.Toastify__toast-body {
  font-size: 14px;
  color: white;
}

/* Class for the progress bar */
.Toastify__progress-bar {
  background: rgb(255, 97, 79);
}

/* Class for the close button. */
.Toastify__close-button {
  color: white;
}

.Toastify__progress-bar--success {
  background: green;
}

.Toastify__progress-bar--error {
  background: rgb(255, 97, 79);
}