.four-oh-four {
    height:100vh;
    overflow:hidden;
    color:white;
    font-size:20px;
    display:flex;
    justify-content: center;
    flex-wrap:wrap;
    
    p {
        margin:3px;
        overflow:hidden;
    }
}